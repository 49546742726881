export const chunkString = (str: string, length: number): string[] => str.match(new RegExp(`.{1,${length}}`, "g")) || []

export const splitStringWithChar = (str: string, char: string, everyN: number): string => chunkString(str, everyN).join(char)

export const toTitleCase = (str?: string): string | null => {
  if (str) {
    return str
      .split(" ")
      .map((s, i) => s[0].toUpperCase() + s.slice(1, s.length).toLowerCase())
      .join(" ")
  }
  return null
}

const ellipsisCharCode = "…"

export const middleEllipsis = (str: string, lengthLimit = 25): string => {
  if (str.length > lengthLimit) {
    const beginningLength = Math.round(lengthLimit * 0.4)
    const endingLength = Math.round(lengthLimit * 0.5)
    return `${str.substring(0, beginningLength)}${ellipsisCharCode}${str.slice(-endingLength)}`
  }
  return str
}

export const kebabToCamelCase = (str: string): string => str.replace(/-./g, (x) => x[1].toUpperCase())

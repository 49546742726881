import { gql } from "@apollo/client"
import { CAMPAIGN_AGGREGATED_METRICS_ITEMISED_FRAGMENT } from "../fragments"

export const GET_CAMPAIGN_AGGREGATED_METRICS_BY_CREATIVE = gql`
  query GetCampaignAggregatedMetricsByCreative(
    $clientId: Int!
    $platformIntegrationId: Int!
    $platform: Platform!
    $platformAdvertiserId: String!
    $platformCampaignId: ID!
    $campaignType: CampaignType!
    $pixelIdsSets: [[String!]!]
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    getCampaignAggregatedMetricsByCreative(
      campaignType: $campaignType
      clientId: $clientId
      platformIntegrationId: $platformIntegrationId
      platform: $platform
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
      pixelIdsSets: $pixelIdsSets
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      __typename
      platformCampaignId {
        platform
        id
      }
      dateFrom
      dateTo
      itemisedMetrics {
        itemId
        itemName
        size
        type
        ...CampaignAggregatedMetricsItemisedFragment
      }
    }
  }
  ${CAMPAIGN_AGGREGATED_METRICS_ITEMISED_FRAGMENT}
`

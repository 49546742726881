import { z } from "zod"
import { FormKpi } from "../../../components/forms/KpiSelector/KPI"
import { KpiName } from "../../../generated/graphql"

const pixelIdsRegex = /^\s*\d+\s*(,\s*\d+\s*)*$/

const isValidPixelIdsString = (key: string) => pixelIdsRegex.test(key)

export const pixelIdsStringSchema = z.string().trim().refine(isValidPixelIdsString, {
  message: "Pixel ids can only be numbers separated by commas",
})

export const formKpiSchema = z
  .object({
    name: z.nativeEnum(KpiName),
    target: z.object({
      integer: z.string().trim().optional(),
      monetary: z.string().trim().optional(),
      percentage: z.string().trim().optional(),
    }),
    constraints: z.object({
      min: z.object({
        target: z.object(
          {
            integer: z.string().trim().optional(),
            monetary: z.string().trim().optional(),
            percentage: z.string().trim().optional(),
          },
          { required_error: "You must set a minimum constraint" }
        ),
      }),
      max: z.object({
        target: z.object(
          {
            integer: z.string().trim().optional(),
            monetary: z.string().trim().optional(),
            percentage: z.string().trim().optional(),
          },
          { required_error: "You must set a maximum constraint" }
        ),
      }),
    }),
    weight: z.string().trim(),
    pixelIds: z.union([z.array(z.string().trim()), pixelIdsStringSchema]).optional(),
  })
  .refine(
    (formKpiValue) =>
      (Number(formKpiValue.constraints?.min.target?.integer) > 0 &&
        Number(formKpiValue.target.integer) > 0 &&
        Number(formKpiValue.constraints?.max.target?.integer) > 0) ||
      (Number(formKpiValue.constraints?.min.target?.monetary) > 0 &&
        Number(formKpiValue.target.monetary) > 0 &&
        Number(formKpiValue.constraints?.max.target?.monetary) > 0) ||
      (Number(formKpiValue.constraints?.min.target?.percentage) > 0 &&
        Number(formKpiValue.target.percentage) > 0 &&
        Number(formKpiValue.constraints?.max.target?.percentage) > 0),
    "The target, minimum and maximum constraints must have values"
  )
  .refine(
    (formKpiValue) =>
      Number(formKpiValue.constraints?.min.target?.integer) < Number(formKpiValue.target.integer) ||
      Number(formKpiValue.constraints?.min.target?.monetary) < Number(formKpiValue.target.monetary) ||
      Number(formKpiValue.constraints?.min.target?.percentage) < Number(formKpiValue.target.percentage),
    "The minimum constraint must be below the target"
  )
  .refine(
    (formKpiValue) =>
      Number(formKpiValue.constraints?.max.target?.integer) > Number(formKpiValue.target.integer) ||
      Number(formKpiValue.constraints?.max.target?.monetary) > Number(formKpiValue.target.monetary) ||
      Number(formKpiValue.constraints?.max.target?.percentage) > Number(formKpiValue.target.percentage),
    "The maximum constraint must be above the target"
  )
  .refine((formKpiValue) => Number(formKpiValue.weight) > 0, "The weight must have a value")
  .refine((formKpiValue) => Number(formKpiValue.weight) <= 10, "The weight cannot be higher than 10")
  .refine(
    (formKpiValue) =>
      formKpiValue.name === KpiName.Cpa
        ? (typeof formKpiValue.pixelIds !== "string" && formKpiValue.pixelIds && formKpiValue.pixelIds.filter((pixelId) => pixelId.length).length > 0) ||
          (typeof formKpiValue.pixelIds === "string" && formKpiValue.pixelIds.length > 0)
        : true,
    "You must enter at least one pixel id if the KPI type is CPA"
  )

export const formValuesSchema = z
  .object({
    mainKpi: formKpiSchema,
    secondaryKpis: z.array(formKpiSchema).nullish(),
  })
  .refine((formValues) => {
    if (formValues.secondaryKpis && formValues.secondaryKpis.length > 0) {
      const allPixelIds = (formValues.secondaryKpis.concat(formValues.mainKpi) as FormKpi[])
        .filter((kpi) => kpi.name === KpiName.Cpa && (kpi.pixelIds?.length ?? 0) > 0)
        .flatMap((kpi) =>
          typeof kpi.pixelIds === "string" ? kpi.pixelIds.split(",").map((pixelId) => pixelId.trim()) : (kpi.pixelIds ?? []).map((pixelId) => pixelId.trim())
        )
        .filter((pixelId) => pixelId.length > 0)

      const uniquePixelIds = [...new Set(allPixelIds)]

      return uniquePixelIds.length === allPixelIds.length
    } else {
      return true
    }
  }, "You cannot select the same pixel more than once")
  .refine(
    (formValues) =>
      formValues.mainKpi.name === KpiName.Cpm ||
      (formValues.secondaryKpis && formValues.secondaryKpis.length > 0 && formValues.secondaryKpis.some((kpi) => kpi.name === KpiName.Cpm)),
    "You must select CPM for one of your KPIs"
  )

import { FC } from "react"
import { Tab, TabList, Tabs, TabPanel, TabPanels, Text } from "@chakra-ui/react"
import { ResetIcon } from "../../../icons"
import { TileProps } from "../../../layouts/TilesGrid"
import GridState from "../../../layouts/TilesGrid/GridState"
import CampaignDashboardController from "../../../pages/CampaignDashboardPage/types"
import colors from "../../../theme/colors"
import { StringKeyObject } from "../../../types"
import { enumEntryFromString } from "../../../utils/enumUtils"
import ListItemLabel from "../../ListItemLabel"
import Tile from "../../Tile"
import { SegmentType } from "../types"

type ListItem = {
  id: string
  name: string
  size?: string
  type?: string
}

type Props = {
  title: string
  listItems: ListItem[]
}

const TabsLists: FC<TileProps<CampaignDashboardController, GridState, StringKeyObject<Props>>> = ({ dashboardController, tileData }) => {
  const { campaignDashboardState, onResetSelectedSegments, onSelectedSegmentsListChange, onSegmentsTabChange } = dashboardController
  const { selectedSegmentsTab, selectedCreativeIds, selectedLineItemIds } = campaignDashboardState

  const tabNames = Object.keys(tileData)
  const getTabTitles = tabNames.map((tabName) => (
    <Tab key={tabName} mr={2}>
      <Text as="b" fontSize="1rem">
        {tileData?.[tabName].title}
      </Text>
    </Tab>
  ))

  const getTabPanelContents = tabNames.map((tabName) => {
    if (tileData?.[tabName]) {
      const { listItems } = tileData[tabName] as Props

      if (listItems?.length > 0) {
        return (
          <TabPanel key={tabName} pl={0} pr={2} py={0} overflowX="hidden">
            {listItems.map((listItem) => {
              if (tabName === SegmentType.Creatives) {
                const { id, name, size, type } = listItem
                const label = `${name} - ${type} - ${size}`
                const isSelected = selectedCreativeIds.includes(id)

                return (
                  <ListItemLabel
                    size="medium"
                    isSelected={isSelected}
                    key={id}
                    label={label}
                    onClick={async () => {
                      onSelectedSegmentsListChange({
                        segmentType: enumEntryFromString(SegmentType, tabName),
                        segments: [id],
                      })
                    }}
                  />
                )
              }
              if (tabName === SegmentType.LineItems) {
                const { id, name } = listItem
                const isSelected = selectedLineItemIds.includes(id)

                return (
                  <ListItemLabel
                    size="medium"
                    isSelected={isSelected}
                    key={id}
                    label={name}
                    onClick={async () => {
                      onSelectedSegmentsListChange({
                        segmentType: enumEntryFromString(SegmentType, tabName),
                        segments: [id],
                      })
                    }}
                  />
                )
              }
              return <></>
            })}
          </TabPanel>
        )
      }
      return <></>
    }
    return <></>
  })

  const hasItemsSelected = selectedCreativeIds.length > 0 || selectedLineItemIds.length > 0
  const resetIconColor = hasItemsSelected ? colors.colors.brand["400"] : colors.colors.brand["200"]

  return (
    <Tile overflowX="hidden" pt={5} pl={5} pb={5} position="relative">
      <Tabs onChange={onSegmentsTabChange} index={selectedSegmentsTab} w="100%" overflowY="hidden">
        <TabList>{getTabTitles}</TabList>
        <TabPanels
          overflowX="hidden"
          overflowY="scroll"
          height="90%"
          mt={2}
          sx={{
            "::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
              paddingRight: "8px",
            },
            "::-webkit-scrollbar-track": {
              background: colors.colors.brand["900"],
            },
            "::-webkit-scrollbar-thumb": {
              background: colors.colors.brand["200"],
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {getTabPanelContents}
        </TabPanels>
      </Tabs>
      <ResetIcon
        color={resetIconColor}
        position="absolute"
        right="20px"
        cursor="pointer"
        onClick={async () => {
          onResetSelectedSegments()
        }}
      />
    </Tile>
  )
}

export default TabsLists

import React, { FC, ReactNode } from "react"
import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react"
import { Link as ReachLink, useNavigate } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import Env, { EnvKey } from "../../Env"
import usePermissions, { canManage, canView } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import { CogIcon } from "../../icons"
import { UserProfile } from "../../models/AuthState"
import { splitStringWithChar } from "../../utils/stringUtils"
import MainLayoutErrorState from "./MainLayoutErrorState"

export interface BreadcrumbItemProps {
  text: string
  onClick?: () => void
}

const createBreadcrumbItem = (index: number, { text, onClick }: BreadcrumbItemProps) => {
  const isCurrentPage = !onClick
  const textDecoration = isCurrentPage ? "unset" : "underline"
  const cursor = isCurrentPage ? "unset" : "pointer"
  return (
    <BreadcrumbItem isCurrentPage={isCurrentPage} key={index}>
      <BreadcrumbLink isCurrentPage={isCurrentPage} onClick={onClick} _hover={{ textDecoration, cursor }}>
        {text}
      </BreadcrumbLink>
    </BreadcrumbItem>
  )
}

interface MainLayoutProps {
  user?: UserProfile
  signOut: () => void
  isErrored?: boolean
  heading?: string
  breadcrumbItems?: BreadcrumbItemProps[]
  centerContentHorizontal?: boolean
  centerContentVertical?: boolean
  paddingTop?: number
  children?: ReactNode
}

const MainLayout: FC<MainLayoutProps> = ({
  user, //
  signOut,
  isErrored = false,
  heading,
  breadcrumbItems,
  centerContentHorizontal = false,
  centerContentVertical = false,
  children,
}) => {
  const { clientResourceUri, userResourceUri } = useResourceUris()
  const { permissions, loading: loadingPermissions } = usePermissions()
  const navigate = useNavigate()

  const handleSignOut = () => {
    signOut()
  }

  const gridSize = "110px"

  return (
    <Flex>
      <Flex direction="column" position="sticky" top={0} pt={10} h="100vh" minWidth={gridSize} w={gridSize}>
        <VStack>
          <Image w={16} src="/59A-logo.svg" />
          <Box pt={10}>
            <Avatar showBorder={true} />
          </Box>
        </VStack>
        {user && !loadingPermissions && (
          <VStack pt={10}>
            {user?.is59A ? (
              canView(permissions, clientResourceUri) && (
                <Box pb={10}>
                  <Link as={ReachLink} to={RoutePaths.clientsList.resolve()}>
                    <Text fontSize="xl">Clients</Text>
                  </Link>
                </Box>
              )
            ) : (
              <Box pb={10}>
                <Link as={ReachLink} to={RoutePaths.integrationsList.resolve(user?.clientId ?? "99")}>
                  <Text fontSize="xl">DSP Seats</Text>
                </Link>
              </Box>
            )}
            {canManage(permissions, userResourceUri) && (
              <Box pb={10}>
                <Link as={ReachLink} to={RoutePaths.userManagement.resolve()}>
                  <Text fontSize="xl">Users</Text>
                </Link>
              </Box>
            )}
          </VStack>
        )}
        <Flex pt={10} justifyContent="center" h="100%">
          <Flex direction="column" alignSelf="flex-end" justifySelf="flex-end">
            <Menu size="sm" placement="top">
              <MenuButton as={IconButton} border="none" aria-label="Settings" icon={<CogIcon />} variant="unstyled" />
              <MenuList bg="brand.700" _hover={{ bg: "brand.700" }} minWidth={20} w={20} px={0}>
                {!user?.is59A && user?.roles.some((role) => role.isAdmin) && (
                  <MenuItem onClick={() => navigate(RoutePaths.accountSettings.resolve())} px={0} paddingLeft={2}>
                    Account Settings
                  </MenuItem>
                )}
                <MenuItem onClick={handleSignOut} px={0} paddingLeft={2}>
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
            <Box py={10}>
              <Text fontSize={13}>App Version</Text>
              <Text fontSize={13}>{splitStringWithChar(Env.getString(EnvKey.UiVersion, "Unknown"), "-", 3)}</Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Grid
        px={8}
        height="100vh"
        width="100%"
        overflowX="hidden"
        templateAreas={`"header"
                  "main"`}
        gridTemplateRows={`90px 1fr`}
        gridTemplateColumns={"1fr"}
        gap={"6"}
      >
        <GridItem area="header" pt={10}>
          {user && user.name.first && <Heading>{`Welcome back, ${user.name.first}`}</Heading>}
        </GridItem>
        <GridItem area="main" pt={0} pb={10} height={`calc(100vh - 7.125rem)`}>
          {isErrored ? (
            <MainLayoutErrorState />
          ) : (
            <>
              {breadcrumbItems && <Breadcrumb>{breadcrumbItems.map((props, i) => createBreadcrumbItem(i, props))}</Breadcrumb>}
              <Flex height="calc(100vh - 12.2rem)" pt={6} direction="column">
                {heading && <Heading className="site-page-header">{heading}</Heading>}
                {centerContentHorizontal || centerContentVertical ? <Center h="100%">{children}</Center> : children}
              </Flex>
            </>
          )}
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default MainLayout

import React, { FC, useMemo } from "react"
import { Box, Center, Divider, Heading, HStack, VStack } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import KpiSelector from "../../../components/forms/KpiSelector"
import { buildInitialFormValues, buildStepData, FormKpi, FormValues, KpiEntries } from "../../../components/forms/KpiSelector/KPI"
import { Option } from "../../../components/forms/SelectInput/SelectBaseUI"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import WizardNavigatePreviousButton from "../../../components/WizardNavigatePreviousButton"
import WizardNavigateSaveButton from "../../../components/WizardNavigateSaveButton"
import { Platform } from "../../../generated/graphql"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import ObjectDiff from "../../../utils/ObjectDiff"
import ManageCampaignData, { Step1Data, Step2Data } from "./ManageCampaignData"
import { formValuesSchema } from "./schema"

// Initial values for each dynamically created secondary KPI
const initialSecondaryKpiValues: FormKpi = {
  name: "",
  target: { integer: "", percentage: "", monetary: "" },
  constraints: {
    min: { target: { integer: "", percentage: "", monetary: "" } },
    max: { target: { integer: "", percentage: "", monetary: "" } },
  },
  weight: "",
  pixelIds: undefined,
}

interface Props extends WizardStepProps<ManageCampaignData, Step2Data> {
  isManaged: boolean
  currencySymbol: string
  pixels: Option[]
  platform: Platform
  updateLoading: boolean
}

const Step2: FC<Props> = (props) => {
  const { onBack, onSave, onNext, stepIndex, wizardData, isManaged, currencySymbol, pixels, platform, updateLoading } = props
  // Get pre-existing data from top-level wizard, if present
  const stepData = wizardData[stepIndex] as Step2Data

  const previousStepData = wizardData[stepIndex - 1] as Step1Data
  const kpis = KpiEntries.filter(
    (kpiEntry) =>
      kpiEntry.campaignType?.find((campaignType) => campaignType === previousStepData.campaignType) !== undefined || kpiEntry.campaignType === undefined
  )

  // Rehydrate data or provide defaults for first view
  const initialValues: FormValues = useMemo(() => buildInitialFormValues(kpis, stepData), [kpis, stepData])

  const { control, getValues, handleSubmit, watch, formState, trigger } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(formValuesSchema),
  })

  const {
    fields: secondaryKpis,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "secondaryKpis",
  })

  const submitAndNext = (values: FormValues) => {
    const newStepData = buildStepData(kpis, values)

    const differ = new ObjectDiff(stepData, newStepData)
    const shouldInvalidateFutureSteps = differ.hasDifferences()

    onNext(newStepData, shouldInvalidateFutureSteps)
  }

  const saveAndNext = async (values: FormValues) => {
    const formIsValid = await trigger()
    if (formIsValid && onSave) {
      const newStepData = buildStepData(kpis, values)

      const differ = new ObjectDiff(stepData, newStepData)
      const shouldInvalidateFutureSteps = differ.hasDifferences()

      onSave(newStepData, shouldInvalidateFutureSteps)
    }
  }

  const watchMainKpi = watch("mainKpi")

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorMessage = (formState.errors as any)[""] && "message" in (formState.errors as any)[""] ? (formState.errors as any)[""].message : ""

  return (
    <>
      <WizardNavigatePreviousButton
        previous={{
          onClick: () => onBack(buildStepData(kpis, getValues())),
          shown: true,
          text: "Back",
        }}
      />
      <form onSubmit={handleSubmit(submitAndNext)}>
        {watchMainKpi && (
          <>
            <Divider />
            <Heading pt={4} size="xl">
              {"Select your KPI(s)"}
            </Heading>
            <Heading paddingY={4} size="lg">
              Main KPI
            </Heading>
            <Box>
              <KpiSelector //
                fieldName="mainKpi"
                kpis={kpis}
                pixels={pixels}
                platform={platform}
                zIndex={9}
                hasConstraints={true}
                currencySymbol={currencySymbol}
                control={control}
                errors={formState.errors}
                watch={watch}
              />
            </Box>
          </>
        )}
        <>
          <Divider paddingTop={4} />
          <Heading paddingBottom={4} paddingTop={2} size="lg">
            Secondary KPIs
          </Heading>
          {secondaryKpis &&
            secondaryKpis.map((kpi, index) => (
              <Box key={`secondaryKpi${kpi.id}`} paddingBottom={4}>
                <KpiSelector //
                  fieldName={`secondaryKpis.${index}` as keyof FormValues}
                  kpis={kpis}
                  pixels={pixels}
                  platform={platform}
                  zIndex={9 - (index + 1)}
                  hasConstraints={true}
                  currencySymbol={currencySymbol}
                  control={control}
                  errors={formState.errors}
                  watch={watch}
                />
                <Button onClick={() => remove(index)}>Remove</Button>
                <Divider color="black" paddingBottom={4} />
              </Box>
            ))}
          {secondaryKpis.length < 3 && (
            <Center paddingBottom={4}>
              <Button onClick={() => append(initialSecondaryKpiValues)} isFullWidth={true}>
                Add Secondary KPI
              </Button>
            </Center>
          )}
        </>

        <VStack h={8} align="flex-start">
          {errorMessage && <ErrorText message={errorMessage} />}
        </VStack>

        <HStack>
          {!isManaged && (
            <WizardNavigateSaveButton
              save={{
                onClick: () => saveAndNext(getValues()),
                text: "Save",
                isDisabled: onSave && updateLoading,
                isLoading: updateLoading,
                shown: watchMainKpi?.name !== undefined,
              }}
            />
          )}
          <WizardNavigateNextButton
            next={{
              text: isManaged ? "Update" : "Manage",
              type: "submit",
              isDisabled: updateLoading,
              isLoading: updateLoading,
              shown: watchMainKpi?.name !== undefined,
            }}
          />
        </HStack>
      </form>
    </>
  )
}

export default Step2
